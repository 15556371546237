import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import {
  Box,
  styled,
  Typography,
  Stepper as St,
  Step,
  useTheme,
  StepLabel,
  useMediaQuery,
  StepContent,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { ConfirmFileTab } from "./ConfirmFileTab";
import { CaptureFileTab } from "./CaptureFileTab";
import { ConfigFileTab } from "./ConfigFileTab";
import { ConfigReqPreviosTab } from "./ConfigReqPreviosTab";
import {
  deleteAllParticipants,
  resetDocsStack,
  resetNewDocument,
  resetNewFile,
  resetPdf,
  resetPrerrequisites,
  unsetForm,
  setTitleGral,
  getFile,
  getFileCustomInfoMasive,
  resetListDocsStackMassive,
  editStack,
} from "../../store/actions";
import { FilePreview } from "./FilePreview";
import { UseImage } from "../../components/UseImage";
import ConfigFirmDrag from "./ConfigFirmDrag";
import { ConfigReqPreviosMassiveTab } from "./ConfigReqPreviosMassiveTab";
import { CaptureFileMassiveTab } from "./CaptureFileMassiveTab";
import { ConfigFileMassiveTab } from "./ConfigFileMassiveTab";
import { FilePreviewMasive } from "./FilePreviewMasive";
import ConfigFirmDragMassiveTab from "./ConfigFirmDragMassiveTab";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#FFFFFF",
  border: "1px solid #C5C8CD",
  zIndex: 1,
  color: "#000",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#FFFFFF",
    border: "1px solid #375F8D",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#FFFFFF",
    border: "1px solid #375F8D",
  }),
  [theme.breakpoints.down("sm")]: {
    width: 25,
    height: 25,
  },
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    left: "calc(-40% + 16px)",
    right: "calc(60% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#375F8D",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#375F8D",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#C5C8CD",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const Stepper = styled(St)(({ theme }) => ({
  margin: "20px auto",
  marginBottom: 20,
  maxWidth: "80%",
  "& .Mui-active": {
    color: theme.palette.primary.main,
  },
  "& .MuiStepLabel-label": {
    fontSize: 14,
  },
  [theme.breakpoints.down("sm")]: {
    transform: "translateX(-5px)",
    margin: "5px auto",
    marginBottom: 30,
  },
  [theme.breakpoints.down("md")]: {
    ".MuiStepLabel-root": {
      alignItems: "flex-start",
    },
    "& .MuiStepLabel-label": {
      textAlign: "left",
    },
  },
  [theme.breakpoints.down("sm")]: {
    margin: "10px auto",
    ".MuiStepLabel-root": {
      alignItems: "flex-start",
    },
    "& .MuiStepLabel-label": {
      textAlign: "left",
      fontSize: 12,
    },
  },
}));

function ColorlibStepIcon(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { active, completed, className } = props;
  const statusPrerequisitos = useSelector(
    (state) => state.File?.item?.Prerequisites?.ActivePrerequisites
  );
  const statusPreSignedEdited = useSelector(
    (state) => state.File.item?.SignatureZones
  );
  const statusPree = localStorage.getItem("ctrlReqPrevios") === "Si";
  const statusPreSigned = localStorage.getItem("ctrlPreFirmado") === "Si";

  const icons = {
    1: (
      <UseImage
        type="Image"
        src="IconActiveStepOne.png"
        width={mobileScreen ? 12.25 : 25}
        height={mobileScreen ? 12.25 : 25}
      />
    ),
    2:
      (active || completed) && (statusPree || statusPrerequisitos) ? (
        <UseImage
          type="Image"
          src="IconActiveStepTwo.png"
          width={mobileScreen ? 12.25 : 22}
          height={mobileScreen ? 12.25 : 25}
        />
      ) : (
        <UseImage
          type="Image"
          src="PreviosIconDisabled.svg"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ),
    3:
      active || completed ? (
        <UseImage
          type="Image"
          src="IconActiveStepThree.png"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ) : (
        <UseImage
          type="Image"
          src="DocumentsIconDisabled.svg"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ),
    4:
      active || completed ? (
        <UseImage
          type="Image"
          src="IconActiveStepFour.png"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ) : (
        <UseImage
          type="Image"
          src="ParticipantsIconDisabled.svg"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ),
    5:
      (active || completed) && (statusPreSigned || statusPreSignedEdited) ? (
        <UseImage
          type="Image"
          src="IconActiveStepFive.png"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ) : (
        <UseImage
          type="Image"
          src="IconPreFirmaDisabled.svg"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ),
    6:
      active || completed ? (
        <UseImage
          type="Image"
          src="IconActiveStepSix.png"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ) : (
        <UseImage
          type="Image"
          src="ProcessIconDisabled.svg"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const NewFile = () => {
  const environmentCtrlSigned = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.EnviromentVars
        ?.IsActiveSignatureZones
  );
  const statusMassive =
    sessionStorage.getItem("ctrlSolicitudMasiva") === "true";

  const folioIdSolicitud = sessionStorage.getItem("FileId");

  const stateRedux = useSelector((state) => state);
  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);
  const statusFile = useSelector(
    (state) =>
      state.File?.item?.FileData?.Stage?.WorkflowInstance?.CurrentStatus
  );
  const statusPrerequisitos = useSelector(
    (state) => state.File?.item?.Prerequisites?.ActivePrerequisites
  );
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const formMethods = useForm({ mode: "onChange" });

  useEffect(() => {
    dispatch(setTitleGral(getTitle()));
  }, [activeStep]);

  const handleNext = () => {
    if (
      activeStep === 0 &&
      (localStorage.getItem("ctrlReqPrevios") === "Si" ||
        statusPrerequisitos === true)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (
      activeStep === 0 &&
      (localStorage.getItem("ctrlReqPrevios") === "No" ||
        statusPrerequisitos === false)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (
      activeStep === 2 &&
      (fileState.item?.Prerequisites?.ActivePrerequisites === false ||
        fileState.item?.Prerequisites?.ActivePrerequisites === null ||
        fileState.item?.Prerequisites?.ActivePrerequisites === undefined)
    ) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const omitirPrevios = () => {
    setActiveStep(2);
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const steps = [
    {
      label: "Creación de la solicitud",
    },
    {
      label: "Requisitos previos",
    },
    {
      label: "Carga de documentos",
    },
    {
      label: "Configuración de participantes",
    },
    {
      label: "Representación de firma",
    },
    {
      label: "Inicio de proceso",
    },
  ];

  const renderComponent = () => {
    if (activeStep === 0) {
      return (
        <ConfirmFileTab
          handleNextStep={handleNext}
          StatusMassive={statusMassive}
        />
      );
    } else if (activeStep === 1) {
      if (statusMassive === true) {
        return (
          <ConfigReqPreviosMassiveTab
            handleNextStep={handleNext}
            handleBackStep={handleBack}
            handleSetStep={handleStep}
            omitirPrevios={omitirPrevios}
          />
        );
      } else {
        return (
          <ConfigReqPreviosTab
            handleNextStep={handleNext}
            handleBackStep={handleBack}
            handleSetStep={handleStep}
            omitirPrevios={omitirPrevios}
          />
        );
      }
    } else if (activeStep === 2) {
      if (statusMassive === true) {
        return (
          <CaptureFileMassiveTab
            handleNextStep={handleNext}
            handleBackStep={handleBack}
            handleSetStep={handleStep}
          />
        );
      } else {
        return (
          <CaptureFileTab
            handleNextStep={handleNext}
            handleBackStep={handleBack}
            handleSetStep={handleStep}
          />
        );
      }
    } else if (activeStep === 3) {
      if (statusMassive === true) {
        return (
          <ConfigFileMassiveTab
            handleNextStep={handleNext}
            handleBackStep={handleBack}
            handleSetStep={handleStep}
          />
        );
      } else {
        return (
          <ConfigFileTab
            handleNextStep={handleNext}
            handleBackStep={handleBack}
            handleSetStep={handleStep}
          />
        );
      }
    } else if (activeStep === 4) {
      if (statusMassive === true) {
        return (
          <ConfigFirmDragMassiveTab
            handleNextStep={handleNext}
            handleBackStep={handleBack}
            handleSetStep={handleStep}
          />
        );
      } else {
        return (
          <ConfigFirmDrag
            handleNextStep={handleNext}
            handleBackStep={handleBack}
            handleSetStep={handleStep}
          />
        );
      }
    } else if (activeStep === 5) {
      if (statusMassive === true) {
        return <FilePreviewMasive handleBackStep={handleBack} />;
      } else {
        return <FilePreview handleBackStep={handleBack} />;
      }
    }
  };

  const getTitle = () => {
    switch (activeStep) {
      case 0:
        return "Creación de la solicitud";
      case 1:
        return "Requisitos previos";
      case 2:
        return "Carga de documentos";
      case 3:
        return "Configuración de participantes";
      case 4:
        return "Representación de firma";
      default:
        return "Creación de la solicitud";
    }
  };

  const stageExpediente = () => {
    switch (statusFile) {
      case "Carga de documentos":
        return setActiveStep(2), sessionStorage.removeItem("ctrlExpediente");
      case "Requisitos Previos":
        return setActiveStep(1), sessionStorage.removeItem("ctrlExpediente");
      case "Configuración de participantes":
        return setActiveStep(3), sessionStorage.removeItem("ctrlExpediente");
      case "Representación de firma":
        return setActiveStep(4), sessionStorage.removeItem("ctrlExpediente");
      case "Vista previa":
        return setActiveStep(5), sessionStorage.removeItem("ctrlExpediente");
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (newFileState.editStack === false) {
  //     var nameProp = newFileState?.file?.Metadata.find(
  //       (item) => item.GroupName === "Expediente" && item.Name === "Nombre"
  //     );
  //     setNameExpedienteCurrent(nameProp?.Value);
  //   } else {
  //     setNameExpedienteCurrent(fileState?.item?.Name);
  //   }
  // }, [newFileState]);

  useEffect(() => {
    return () => {
      dispatch(resetNewFile());
      dispatch(resetPdf());
      dispatch(resetNewDocument());
      dispatch(resetPrerrequisites());
      dispatch(unsetForm());
      dispatch(deleteAllParticipants());
      dispatch(resetDocsStack());
      dispatch(resetListDocsStackMassive());
    };
  }, [dispatch]);

  useEffect(() => {
    if (sessionStorage?.ctrlExpediente === "true") {
      stageExpediente();
    }
  }, [statusFile]);

  const selectedStep = (item) => {
    if (statusMassive) {
      return null;
    }

    const hasPermissions = Object.values(newFileState?.participants).some(
      (array) => array.length > 0
    );
    dispatch(editStack(true));
    if (item !== 0) {
      dispatch(getFile({ FileId: folioIdSolicitud }));
    }
    console.log("redux", hasPermissions);
    // FALTA CONDICIONAR SI TIENE PRERRQUISITOS Y PARA EL VALOR 1 SI HAY REQUISITOS LO DEJE PASAR
    dispatch(editStack(true));
    if (statusMassive) {
      dispatch(
        getFileCustomInfoMasive({
          Body: {
            FileId: folioIdSolicitud,
          },
        })
      );
    } else {
      dispatch(getFile({ FileId: folioIdSolicitud }));
    }

    if (folioIdSolicitud && (item === 2 || item === 0)) {
      setActiveStep(item);
    } else if (
      folioIdSolicitud &&
      item === 1 &&
      (localStorage.getItem("ctrlReqPrevios") === "Si" ||
        statusPrerequisitos === true)
    ) {
      setActiveStep(item);
    } else if (
      folioIdSolicitud &&
      item === 3
      // &&
      // (fileState?.item?.Documents?.length > 0 ||
      //   newFileState?.documentsStack?.length > 0)
    ) {
      setActiveStep(item);
    } else if (
      folioIdSolicitud &&
      item === 4
      // && (fileState?.item?.Participants?.length > 0 || hasPermissions === true)
    ) {
      setActiveStep(item);
    } else if (
      folioIdSolicitud &&
      item === 5
      // && (fileState.item.Participants.length > 0 || hasPermissions === true)
    ) {
      setActiveStep(item);
    }
  };

  return (
    <FormProvider {...formMethods} theme={theme}>
      <Box
        sx={{
          width: "100%",
          maxWidth: "95%",
          margin: "0 auto",
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100% !important",
          },
        }}
      >
        {stateRedux?.GenericData?.title ? (
          <>
            <Box
              sx={{
                maxWidth: 1000,
                margin: "0 auto",
                display: { xs: "flex", md: "none" },
              }}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "40px",
                  lineHeight: "130%",
                  [theme.breakpoints.down("sm")]: {
                    margin: "5px 10px",
                    fontSize: "17px",
                    fontWeight: "700",
                  },
                }}
              >
                {stateRedux?.GenericData?.title}
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: 1000,
                margin: "0 auto",
                display: { xs: "flex", md: "none" },
              }}
            >
              <Typography>
                {newFileState?.editStack === true ? (
                  <Box sx={{ mt: 0, ml: 1.5 }}>
                    <Typography variant="h6">
                      Folio: {fileState?.item?.FileData.Id} -{" "}
                      {fileState?.item?.Name}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ mt: 0, ml: 1.5 }}>
                    <Typography variant="h6">
                      Folio:{" "}
                      {newFileState?.file?.Id
                        ? `${newFileState?.file?.Id} - `
                        : "Nuevo"}{" "}
                      {newFileState?.file?.Reference}
                    </Typography>
                  </Box>
                )}
              </Typography>
            </Box>
          </>
        ) : null}

        {/* {newFileState.file || newFileState.editStack ? (
          <Box sx={{ maxWidth: 1000, margin: "0 auto" }}>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              {`Folio ${newFileState?.file?.Folio ?? fileState?.item?.FileData?.Id
                } - ${newFileState.editStack
                  ? fileState?.item?.Name
                  : nameExpedienteCurrent
                }`}
            </Typography>
          </Box>
        ) : null} */}

        <Stepper
          alternativeLabel={mobileScreen ? false : true}
          connector={<QontoConnector />}
          activeStep={activeStep}
          orientation={mobileScreen ? "vertical" : "horizontal"}
        >
          {steps.map((step, index) => {
            if (
              step.label === "Representación de firma" &&
              environmentCtrlSigned === false
            ) {
              return null;
            } else {
              return (
                <Step
                  onClick={() => selectedStep(index)}
                  key={step.label}
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      width: "100% !important",
                    },
                  }}
                >
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {index === activeStep ? (
                      <spam style={{ fontWeight: 650 }}>{step.label}</spam>
                    ) : (
                      step.label
                    )}
                  </StepLabel>
                  {mobileScreen && (
                    <StepContent sx={{ paddingLeft: 2 }}>
                      {renderComponent()}
                    </StepContent>
                  )}
                </Step>
              );
            }
          })}
        </Stepper>
        {!mobileScreen && renderComponent()}
      </Box>
    </FormProvider>
  );
};
